import React from "react"
import {Link} from "gatsby"

// import LogosBeechcraft from "../images/icons/logos_beechcraft_textron.svg"
import LogoTextron from '../images/icons/Logo_Textron_n.png'
import LogosLeonardo from "../images/icons/logo_leonardo.svg"
import imgPage from "../images/headers/Logos_Ale_Dealerships_Blue.svg";
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./dealerships.module.scss"
import Header from "../components/header";

export default () => {
    const extraInfo = [
        {name: '', mail: 'info@ale.mx', phone: '55 4202 0920'}
    ]
    return (
        <Layout extraInfo={extraInfo}>
            <Header LogoPage={imgPage}/>
            <SEO title="Dealerships"/>
            <div className={style.dealerships}>
                <div className={style.content}>
                    <div className={style.hover}>
                       <div className={style.item}>
                           <img src={LogoTextron} alt={'Textron'}/>
                           <Link to="/a">Conozca Más</Link>
                       </div>
                        <div className={`${style.item} ${style.small}`}>
                            <LogosLeonardo/>
                            <a className={style.min} href='https://www.leonardocompany.com/en/air/helicopters' rel="noopener noreferrer"
                               target="_blank">Conozca Más</a>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

